let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let gamesDbName = 'games';
let studentsDbName = 'students';
let usersDbName = 'users';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-digitalgrislight-test.web.app';
	apiURL = 'https://cgl-digitalgrislight-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-digitalgrislight-demo.web.app';
		apiURL = 'https://cgl-digitalgrislight-demo.web.app/api/';
		gamesDbName = 'games-demo';
		studentsDbName = 'students-demo';
	}
}
if (env === 'production') {
	gameUrl = 'klikpaagrisen.dk';
	apiURL = 'https://cgl-digitalgrislight-pro.web.app/api/';
}


const appConfig = {
	env: env,
	name: 'Digital Gris Light',
	apiURL: apiURL,
	gameUrl: gameUrl,
	useStreaks: false,
	limitStreaksPerTheme: false,
	cookieDataName: 'digital_gris_light_data',
	gamesDbName,
	studentsDbName,
	usersDbName
};

export default appConfig;
