/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {quizzesData} from 'data/quizzes-data';
import {orderData} from 'data/order-data';
import {sortData} from 'data/sort-data';
import {pairsData} from 'data/pairs-data';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getPointsIllustrationData} from 'helpers/points-helper';
import {isThemeCompleted} from 'helpers/progress-helper';
import ThemeController from 'components/theme/theme-controller';
import './theme-overview.scss';

const ThemeOverview = ({themes, goToTheme, themeId, playerData, updatePlayerData, showHint, setHint}) => {
	return (
		<div className="ThemeOverview">
			<div className="ThemeOverview-wrap">
				{themeId
					? <ThemeController
						playerData={playerData}
						updatePlayerData={updatePlayerData}
						goToTheme={goToTheme}
						themeId={themeId}
						showHint={showHint}
						setHint={setHint}
					/>
					: <React.Fragment>
						<div className="ThemeOverview-title">{generalUiTexts.quizzes}</div>
						<div className="ThemeOverview-themes">
							{themes.map((theme) => {
								/* Get challenges of theme */
								let challengesData = JSON.parse(JSON.stringify([...quizzesData, ...orderData, ...sortData, ...pairsData]));
								let themeChallengesData = challengesData.filter((challengeData) => {
									return (
										challengeData.themeId === theme.id &&
										challengeData.challengeNumber && challengeData.challengeNumber >= 0
									);
								});
								themeChallengesData = sortArrayByProperty(themeChallengesData, 'challengeNumber', 'ASC');
								let filledDivisions = getPointsIllustrationData(themeChallengesData, playerData).filledDivisions;
								let themeOverviewPigImg = ' ThemeOverview-theme--bronze';
								if (filledDivisions.lastIndexOf(true) === 1 || filledDivisions.lastIndexOf(true) === 2) {
									themeOverviewPigImg = ' ThemeOverview-theme--silver';
								}
								if (filledDivisions.lastIndexOf(true) === 3) {
									themeOverviewPigImg = ' ThemeOverview-theme--gold';
								}
								let completed = isThemeCompleted(themeChallengesData, playerData);
								return <div key={theme.id} className={'ThemeOverview-theme' + (completed ? themeOverviewPigImg : '')}
									onClick={() => {goToTheme(theme.id);}}>{theme.name}</div>;
							})}
						</div>
					</React.Fragment>
				}
			</div>
		</div>
	);
};

ThemeOverview.propTypes = {
	themes: PropTypes.array.isRequired,
	goToTheme: PropTypes.func.isRequired,
	themeId: PropTypes.number,
	updatePlayerData: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	showHint: PropTypes.func.isRequired,
	setHint: PropTypes.func.isRequired
};

export default ThemeOverview;