import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './image-loader.scss';

class ImageLoader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.imagesPreloadedSuccessCount = 0;
		this.imagesPreloadedErrorCount = 0;
	}

	/**
	 * Preload images
	 */
	preloadImage = (status) => {
		/* Increase success / error counters */
		if (status === 'ok') {
			this.imagesPreloadedSuccessCount = this.imagesPreloadedSuccessCount + 1;
		} else {
			this.imagesPreloadedErrorCount = this.imagesPreloadedErrorCount + 1;
		}

		/* Check if all images have been preloaded */
		if ((this.imagesPreloadedSuccessCount + this.imagesPreloadedErrorCount) >= this.props.images.length) {
			this.props.handlePreloadImage(true);
		}
	};

	render() {
		return (
			<div className="ImageLoader">
				{this.props.images.map((fileName, index) => {
					let imgSrc = 'this-file-does-not-exist';
					try {
						imgSrc = require('assets/images/' + fileName);
					} catch (e) {
						console.error('Could not find image: assets/images/' + fileName);
					}
					return (
						<img
							key={index}
							src={imgSrc}
							alt="preload"
							onLoad={() => {this.preloadImage('ok');}}
							onError={() => {this.preloadImage('error');}}
						/>
					);
				})}
			</div>
		);
	}
};

ImageLoader.propTypes = {
	handlePreloadImage: PropTypes.func.isRequired,
	images: PropTypes.array.isRequired
};

export default ImageLoader;