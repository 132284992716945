import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import imagesData from 'data/images-data';
import Loading from 'components/loading/loading';
import ThemeOverviewController from 'components/themes-overview/theme-overview-controller';
import ExploreController from 'components/explore/explore-controller';
import Page from 'components/page/page';
import ImageLoader from 'components/image-loader/image-loader';

class StudentController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			game: {
				themeIds: [9, 5, 6, 7]
			},
			playerData: null,
			themeStreakUnlocked: false,
			showGameCompletedPopup: false,
			themeData: null,
			challengesData: [],
			themeChallengesData: [],
			hint: null,
			hintVisible: false,
			isPreloadingImages: true,
			preloadImages: true
		};

		this.unsubscribeStudent = null;
	}


	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Get user id */
		const cookieData = getCookie(appConfig.cookieDataName);

		if (!cookieData) {
			/* No cookie data (should not happen) */
			this.props.handleExitGame();
		} else {
			const cookieDataObj = JSON.parse(cookieData);
			if (cookieDataObj.userId && cookieDataObj.userId.length > 0) {
				/* User already exists, subscribe to their data */
				this.subscribeToStudent(cookieDataObj.userId).then(() => {
					this.setState({isLoading: false});
				});
			} else {
				/* User does not exist, create and subscribe to data */
				this.createNewUser().then((response) => {
					if (response.status === 'success' && response.userId) {
						setCookie(appConfig.cookieDataName, JSON.stringify({userId: response.userId}));
						this.subscribeToStudent(response.userId).then(() => {
							this.setState({isLoading: false});
						});
					} else {
						/* Could not create new user */
						console.error(response);
						this.props.handleExitGame();
					}
				}).catch((error) => {
					/* Could not create new user */
					console.error(error);
					this.props.handleExitGame();
				});

			}
		}
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / student */
		if (this.unsubscribeStudent !== null) this.unsubscribeStudent();

		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	};	

	
	/**
	 * Create new user
	 * @returns 
	 */
	createNewUser = () => {
		return new Promise((resolve)=>{
			apiHelper('student/create-student', {}).then(
				(response) => {
					resolve(response);
				},
				(rejection) => {
					resolve(rejection);
				}
			);	
		});
	};

	/**
	 * Subscribe to user data
	 * @param {string} userId
	 */
	subscribeToStudent = (userId) => {
		if (this.unsubscribeStudent !== null) this.unsubscribeStudent();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeStudent = db.collection(appConfig.studentsDbName).doc(userId).onSnapshot((doc) => {
				if (doc.exists) {
					let data = doc.data();
					data.id = doc.id;
					this.setState({ playerData: data }, () => {
						resolve({ status: 'ok' });
					});
				} else {
					this.props.handleExitGame();
				}
			},
			(error) => {
				console.error('could not get student: ', error);
				this.props.handleExitGame();
			}
			);
		});
	};


	/**
	 * Update student data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updatePlayerData = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update student */
		let userId = this.state.playerData.id;
		return new Promise((resolve)=>{
			if (userId) {
				const db = firebase.firestore();
				db.collection(appConfig.studentsDbName).doc(userId).update(updates).then(() => {
					resolve({status: 'ok'});
				}, (error) => {
					resolve({status: 'error', error: error});
				});
			} else {
				resolve({status: 'error', error: 'no-player-id'});
			}
		});
	};

	/* Reset student data (testing purposes) */
	resetData = () => {
		this.updatePlayerData({challenges: [], points: 0, completed: null});
	};

	/* Show an animation to area where student needs to navigate to (hint button clicked) */
	showHint = () => {
		this.setState({hintVisible: true});
	};

	/* Set hint data from question */
	setHint = (hint) => {
		this.setState({
			hintVisible: false,
			hint: hint
		});
	};

	/* Check if all images have been preloaded */
	handlePreloadImage = (preloadingFinished) => {
		if (preloadingFinished) {
			this.setState({isPreloading: false, preloadImages: false});
		}
	};

	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading || this.state.isPreloading) {
			return (
				<React.Fragment>
					<Loading
						handleExitGame={this.props.handleExitGame}
					/>
					{this.state.preloadImages
						&& <ImageLoader images={imagesData} handlePreloadImage={this.handlePreloadImage} />};
				</React.Fragment>
			);
		};

		return <Page handleExitGame={this.props.handleExitGame}>
			<ExploreController hint={this.state.hintVisible ? this.state.hint : null}/>
			<ThemeOverviewController
				playerData={this.state.playerData}
				updatePlayerData={this.updatePlayerData}
				game={this.state.game}
				setHint={this.setHint}
				showHint={this.showHint}
			/>
			{appConfig.env !== 'production' && 
				<div className="ResetBtn" onClick={() => {this.resetData();}}>Reset</div>}
		</Page>;
	}
}

StudentController.propTypes = {
	handleExitGame: PropTypes.func.isRequired
};

export default StudentController;
