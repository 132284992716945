const exploreData = [
	{
		id: 'farm',
		name: 'Hjemme på gården',
		instruction: '',
		image: 'img-farm.svg',
		topics: [
			{
				id: 'farmer-life',
				name: 'Landmanden',
				image: 'img-farmer.svg',
				intro: `Det er landmandens job at sikre sig, at dyrene har det godt, og har det, de har brug for, fra de bliver født til de bliver slagtet.

				En landmand, der har grise, har mange forskellige roller på sin gård. Han skal både være dyrepasser, kunne køre på marken og lave korn og være håndværker, hvis noget går i stykker. 
				
				Mange landmænd kan godt lide den dynamik, der er i hverdagen. At to dage aldrig er ens.`,
				introImage: null,
				subtopics: [
					{
						id: 1,
						name: 'Landbrugets udvikling',
						text: `For 100 år siden var landbruget det største erhverv i Danmark. Derfor boede de fleste danskere på landet. 
						Dengang havde landmændene flere forskellige slags dyr og afgrøder på deres marker. 
						
						I dag er det anderledes og de fleste landbrug har kun én type dyr på gården, f.eks. grise.`,
						video: null,
						recipe: null,
						image: null,
						funFact: `Man begyndte at gøre dyr tamme for ca. 15.000 år siden. Hunden var det første dyr man gjorde tam.`
					},
					{
						id: 2,
						name: 'Landmandsliv',
						text: `Mange mennesker tror, at landmanden har et lidt ensomt job, men sådan er det slet ikke. 

						De fleste landbrug er i dag så store, at der er flere ansatte, men selv hvis landmanden er alene om sin gård, så er der meget kontakt til andre mennesker. 
						
						Landmanden har nemlig et stort team af samarbejdspartnere, som ofte kommer forbi: dyrlægen, landbrugsrådgiveren, smeden, vvs'eren, nabo-landmænd m.m. 
						
						De lokale landbrugscentre har også mange tilbud til landmænd - både faglige og sociale, så en landmand har altid mulighed for at møde sine kollegaer.`,
						video: null,
						recipe: null,
						image: null,
						funFact: ``
					}
					
				]
			},
			{
				id: 'pig-transformation',
				name: 'Fra pattegris til slagtegris',
				image: 'the-pig.svg',
				intro: `Her kan du lære om hvor grisen kommer fra, de forskellige griseracer og hvordan vi opdrætter grise i Danmark. 
 
				Vi vil også gerne fortælle dig om hvordan landmanden arbejder med dyrevelfærd.`,
				introImage: null,
				funFact: 'En gris vejer cirka 110 kg, når den bliver slagtet.',
				subtopics: [
					{
						id: 1,
						name: 'Slagtegrisens liv',
						text: 'Grisen er et flokdyr og vil helst være sammen med andre grise. I flokken bestemmer nogle søer mere end andre. Der er en fast rangorden og hver so har kæmpet sig til en særlig rang.  <br/><br/>'+
						
						'Søerne kender hinanden på lugten og udseendet og ved, hvem der bestemmer og hvem der ikke gør.<br/><br/>'+
						
						'Grise bliver kønsmodne, altså klar til at få unger, når de er mellem 6 og 8 måneder gamle. Soen er drægtig i 3 måneder, 3 uger og 3 dage.<br/><br/>'+
						
						'Der er mange forskellige måder at holde grise på. De allerfleste grise lever indendørs i en svinestald.<br/><br/>'+
						
						'Slagtegrisen er i 3 forskellige stalde inden den skal slagtes; Først i farestald, så i smågrisestald og til sidste i slagtesvinestald.',
						video: null,
						recipe: null,
						image: 'Grisestalde.png',
						funFact: `Det er lettere at flytte grise rundt i stalden, hvis der er mere lys, der hvor de skal hen end hvor de kommer fra.`,
						subtopics2: [
							{
								id: 1,
								name: 'Griseracer',
								text: 'De griseracer, man møder hos den danske landmand er; Landrace, Yorkshire og Duroc. De har alle deres særlige kendetegn.<br/>'+

								'<br/> Landracen: '+
								'<ul> ' +
								'<li> Har lyserød hud og store øre der hænger ned foran øjnene. </li> '+
								'<li> Soen får ca. 15 grise pr. fødsel. Den har meget mælk i patterne, så der er mange grise, der overlever. </li> '+
								'<li> Landrace er ikke fed, men den har meget kød på kroppen. </li> '+
								'<li> Landrace er en meget populærgris, også i andre lande. Så Danmark sælger, ud over svinekødet, også levende dyr - som avlsdyr. </li> '+
								'</ul>'+
								'<br/> Yorkshire: '+
								'<ul> ' +
								'<li> Kommer oprindelig fra England. </li> '+
								'<li> Den har lyserød hud og lyse børster. </li> '+
								'<li> Kroppen er lang, hovedet er kort, og ørerne står lige op. </li> '+
								'<li> Yorkshire-soen får ca. 15 grise pr. fødsel, og den passer godt på sine unger. </li> '+
								'<li> Yorkshire har meget magert kød. </li> '+
								'</ul>'+
								'<br/> Duroc-racen: '+
								'<ul> ' +
								'<li> Den er rødbrun med mørke børster. </li> '+
								'<li> Grisens krop er kort, og ørerne hænger ned foran øjnene. </li> '+
								'<li> Den giver lidt mindre kød end de andre griseracer, men den vokser til gengæld hurtigere. </li> '+
								'<li> Durocen stammer oprindelig fra USA og Canada. </li> '+
								'<li> Den får ca. 10 grise pr. kuld. </li> '+
								'</ul> <br/> '+
								'Landmanden krydser ofte de 3 griseracer. Det betyder, at han f.eks. lader en Landrace-so parre sig med en Yorkshire-orne. Grisene bliver en blanding af de 2 racer. Sådan får landmanden de bedste grise.',
								video: null,
								recipe: null,
								image: 'Griseracer.png',
								funFact: 'De fleste slagtesvin er ”gadekryds". Det vil sige at de er en blanding af de forskellige griseracer.'
							},
						]
					},
					{
						id: 2,
						name: 'Hvordan holder man gris?',
						text: 'I den danske grise-produktion bruges der 3  forskellige produktions-systemer til opdræt af grise:' +
						'<ul> '+
						'<li> Konventionelt </li> '+
						'<li> Økologisk </li> '+
						'<li> Friland </li> '+
						'</ul> '+
						'Langt de fleste danske grise er konventionelle grise.'+
						
						'Klik videre og lær mere om de 3 produktionssystemer.',
						video: null,
						recipe: null,
						image: null,
						funFact: `Man kan opdrætte grise på forskellige måder. 
						På fagsprog kalder vi det, at vi har forskellige "produktions-systemer".`,
						subtopics2: [
							{
								id: 1,
								name: 'Konventionelt',
								text: `En konventionel gris lever hele sit liv inde i stalden. 

								0 - 5 uger:
								Den nyfødte gris lever i farestalden med sin mor. Når grisen ikke længere har brug for mælk, flytter den sammen med sine søskende til en smågrise-stald.
								
								
								5 - 12 uger:
								Grisen lever i smågrisestalden indtil den vejer 25-30 kg. Når grisene bliver 12 uger gamle, begynder de at gå i puberteten. Her bliver de - nøjagtig som mennesker - lidt stride at have med at gøre, og får brug for mere plads. Derfor bliver grisene flyttet til slagtegrise-stalden. 
								
								12-25 uger:
								Grisen lever i slagtegrisestalden indtil, at den vejer cirka 110 kg. Nu er grisen klar til slagtning. 
								
								Hver enkelt slagtegris mærkes med landmandens mærke. Så ved man, hvor alle grise kommer fra.`,
								video: null,
								recipe: null,
								image: 'Lille-Gris.jpg',
								funFact: `"Konventionel" betyder "almindelig".`,
								sustainability: `Grise kan ikke svede. Alle indendørs grisestalde er derfor indrettet sådan at grisene både kan ligge et koldt og et varmt sted i stalden. Sådan regulerer en gris nemlig sin temperatur ude i naturen.`,
							},
							{
								id: 2,
								name: 'Økologisk',
								text: '0-7 uger: <br/>'+
								'Økologiske grise bliver født på friland i en farehytte og bor sammen med deres mor, indtil de er 7 uger gamle. Når smågrisene er færdige med at få mælk fra deres mor, bliver de flyttet til en økologisk grisestald.<br/><br/>'+
								
								'7-25 uger:<br/>'+
								'I den økologiske grisestald går grisene på et indendørsareal med masser af halm, men grisene har også mulighed for at komme udenfor i en løbegård. Grisen bliver slagtet når den vejer ca. 113. kg. <br/><br/>'+
								
								'Det foder den økologiske gris æder skal være op til 95% økologisk, ikke genmodificeret og består bl.a. af grovfoder, som kan være frisk græs, hø og rodfrugter. <br/><br/>'+
								
								'Den økologiske gris må ikke få medicin, medmindre en dyrlæge først har tilset dyret. Hvis grisen får medicin mere end én gang, kan kødet ikke længere kaldes økologisk.',
								video: null,
								recipe: null,
								image: null,
								funFact: '"Økologi" kommer fra græsk og betyder "Læren om naturens husholdning".'
							},
							{
								id: 3,
								name: 'Friland',
								text: 'Frilandsgrisene er, ligesom de økologiske grise, kendetegnet ved, at de har mulighed for at gå udenfor hele året rundt. De kan dog have en lidt mindre mark at gå på.<br/><br/>'+

								'De økologiske grise og frilandsgrisene har også det til fælles at, når soen skal fare (føde), så bygger den rede i en farehytte. Dog bor frilandsgrisene, modsat de økologiske grise, hos deres mor i 5 uger. Når de 5 uger er gået, flytter pattegrisene til stalde, hvor de har løbegårde, så de fortsat har mulighed for at være ude.',
								video: null,
								recipe: null,
								image: null,
								funFact: null
							},
							{
								id: 4,
								name: 'Dyrevelfærd',
								text: 'I Danmark er der stort fokus på god dyrevelfærd, og landmændene arbejder meget med at udnytte dyrenes naturlige instinkter til at forbedre trivsel. Her er et eksempel på, hvordan man undgår at stresse grisene, når de f.eks. skal flyttes til en ny stald:<br/><br/>'+
								'<ul>'+
								'<li> Grise vil gerne bevæge sig opad og mod lys. Derfor er arealer, der skal bruges til at flytte grise  indrettet med let skå gulv og lys, der kan indstilles.</li> '+
								'<li> Grise er flokdyr. Hvis du kan få en nysgerrig gris til at gå fremad, så følger resten af flokken automatisk med. </li>'+
								'</ul><br/>'+
								'Selvom vi er dygtige til dyrevelfærd i Danmark, så kan vi altid blive bedre. Derfor forskes der også hele tiden i hvordan vi kan få endnu bedre dyrevelfærd.',
								video: null,
								recipe: null,
								image: null,
								funFact: 'Er grisen stresset, kan det smages i kødet, så det er etisk og økonomisk fornuftigt at arbejde med dyrevelfærd.'
							},
							{
								id: 5,
								name: 'Grisens mad',
								text: `Når grisen er færdig med at die hos sin mor,  spiser den foder, som består af korn, der er blandet med et vitamin- og mineraltilskud. Kornet kan være hvede, byg eller havre. 

								Der bliver også blandet proteinpulver i foderet. Proteinet kan komme fra soja eller hestebønner.  
								
								Nogle landmand giver deres grise tørfoder, mens andre blander kornet op med vand, så det bliver til grød. 
								
								De økologiske grise får økologisk korn i foderet.`,
								video: null,
								recipe: null,
								image: null,
								funFact: ''
							},
						]
					},
					{
						id: 3,
						name: 'Grisen i et historisk perspektiv',
						text: 'For 10.000 år siden begyndte mennesker at fodre vildsvin for at gøre dem tamme, og lige så stille blev vildsvinene til husdyr.  <br/><br/>'+

						'Det tamme svin kom til Danmark fra Sydeuropa for 5.000-6.000 år siden. Tamgrisene blev ikke holdt i indhegninger, men gik frit omkring. På den tid lignede tamgrisen og vildsvinet hinanden langt mere hinanden, end de gør i dag.  <br/><br/>'+
						
						'Vildsvinet og tamgrisen ligner stadig hinanden, men der er forskelle:  <br/><br/>'+
						'<ul>'+
						'<li>Vildsvinet har en mørk og kraftig pels, og vejer ca. 180 kg når den er voksen. </li>'+
						'<li>Tamgrisen har næsten ingen pels og kan komme til at veje op til 450 kg. </li>'+
						'</ul> <br/>'+
						'I 1660 besluttede den danske kongen, at bønderne skulle betale en afgift for at have grisene til at gå i skoven. Derfor begyndte bønderne at bygge grisestier med hegn for at holde grisene inde. <br/><br/>'+
						
						'I 1800 begyndte man at holde grise i grisestalde og i  1880 begyndte bønderne at slå sig sammen om større brug- og griseproduktion.',
						video: null,
						recipe: null,
						image: 'Grisens-udvikling.jpg',
						image2: 'Grisens-udvikling-2.jpg', 
						funFact: `Tamgrise stammer fra vildsvin`
					},
				],
			}
		]
	},
	{
		id: 'butchery',
		name: 'Med på slagteriet',
		instruction: '',
		image: 'img-butchery.svg',
		topics: []
		// removed: happens-at-butchery, butcher, engine-room
	},
	{
		id: 'consumer',
		name: 'Mød Forbrugeren',
		instruction: '',
		image: 'img-consumer.svg',
		topics: [
			{
				id: 'food-service',
				name: 'Foodservice',
				image: 'img-food-service.svg',
				intro: 'Foodservice- sektoren kan deles op i to overordnede kategorier; den private og den offentlige. <br/><br/>'+
				'Den private er b.la. restauranter, hoteller og kantiner - hvorimod den offentlige er køkkener på hospitaler og plejehjem.  <br/><br/>'+
				'Foodservice sektoren har i 2020 og 2021 været hårdt ramt af Covid-19 pandemien, der bl.a. medførte nationale nedlukninger. De nationale nedlukninger har haft negative konsekvenser for alle i foodservice sektoren - fra restauranterne, der var tvunget lukket til offentlige kantiner, hvor mange ansatte var tvunget til hjemmearbejde.'
				,
				introImage: null,
				funFact: `I 2020 stod den private sektor for 79% af den samlede omsætning, mens den offentlige stod for 21%.`,
				subtopics: [
					{
						id: 1,
						name: 'Pølsevognen',
						text: `D. 18 januar 1921 kunne man besøge den første pølsevogn i Danmark – altså fyldte pølsevognen 100 år i januar 2021. I pølsevognenes storhedstid (60’erne og 70’erne) stod der omkring 700 pølsevogne rundt i Danmark.`,
						video: null,
						recipe: null,
						image: null,
						funFact: `Når du bestiller en "nissearm" i pølsevognen, får du serveret en rød pølse.`
					}
				]
			},
			{
				id: 'detail',
				name: 'Detail',
				image: 'img-detail.svg',
				intro: `Detailhandel er et overordnet begreb for salg af varer til privatforbrugere og slutbrugere. Det vil sige at detailhandlen spænder bredt fra fysiske supermarkeder og varehuse til internetbutikker. I Danmark er Coop og Dansk Supermarked de to største aktører, når det kommer til dagligvarer.

				De slutprodukter du finder i supermarkederne kan deles op i tre kategorier; fresh, canned og processed. Hver kategori indeholder mange forskellige produkter. Gå på opdagelse i de forskellige kategorier og se hvor meget du ved om dem.`,
				introImage: null,
				sustainability: `Hver dansker udleder i gennemsnit 17 tons CO2 om året, hvor 25% kan tilskrives vores forbrug af fødevarer.`,
				funFact: `De 18-25 årige spiser sjældnere grisekød end den ældre del af befolkningen.`,
				subtopics: [
					{
						id: 1,
						name: 'Plantebaseret',
						text: `Salget af vegetariske og veganske produkter er i kraftig vækst i Danmark. 
						Ved at spise delvist eller helt vegetarisk skånes flere dyreliv og miljøet belastes mindre, idet vegetarisk fødevareproduktion medfører mindre klimabelastning end animalsk fødevareproduktion. 
						
						Produkterne i denne kategori spænder helt fra plantebaseret pålæg til færdigretter.`,
						video: null,
						recipe: null,
						image: null,
						funFact: `Særligt de unge er interesseret i et større udbud og variation indenfor de plantebaserede fødevarer.`,
						sustainability: `Der er en stigende efterspørgsel efter fødevarer, der bidrager positivt til klima, miljø og bæredygtighedsdagsordenen.`,
						subtopics2: [
							{
								id: 1,
								name: 'Veggie pålæg',
								text: `Veggie pålæg er et kødfrit alternativ til madpakken. Produkterne er lavet af æggevide og vegetabilsk olie, som giver et naturligt højt proteinindhold. 
								Veggiepålæggen gør det let og lækkert at tage det etiske valg, når der lægges pålæg på bordet.`,
								video: null,
								recipe: null,
								image: null,
								funFact: `Veggie pålæg smager og ligner traditionelle kødpålægsprodukter og har samme konsistens.`
							},
							{
								id: 2,
								name: 'Færdigretter',
								text: `Veggie færdigretter er nemme og pantebaseret aftensmåltider. Retterne er baseret på ærteprotein og er derfor en god kilde til protein. Det er måltider der tager 5-6 minutter og som gør det nemmere at vælge vegetarisk.  

								Den grønne slagter har blandt andet lavet færdigretterne; Veggie Tandoori og Veggie Biryani der er indisk-inspireret vegetariske retter.`,
								video: null, 
							
								recipe: null,
								image: null,
								funFact: null,
								cuttingInstructions: null,
								sustainability: null,
							},
							{
								id: 3,
								name: 'Plantebaseret erstatninger',
								text: `Plantebaserede køderstatninger giver dig mulighed for at spise grønnere, men stadig holde fast i de klassiske hverdagsretter. Køderstatningerne er lavet af ærte- og bønneprotein og kommer med og uden tilsat krydderi. 

								Der findes forskellige plantebaseret køderstatninger, blandt andet Økologiske Veggie Bites, Veggie BBQ Burger og Veggie Fars fra Den Grønne Slagter.`,
								video: null, 
							
								recipe: null,
								image: null,
								funFact: 'Flere og flere ønsker at reducere deres forbrug af kød af hensyn til miljøet og for at skåne dyrene	',
								cuttingInstructions: null,
								sustainability: null,
							},
						],
					},	
					{
						id: 2,
						name: 'Fresh',
						text: `Fresh er den kategori af svinekød i detailhandlen, der indeholder fersk kød såsom kotelleter, hakket svinekød m.m. Alle produkter i denne kategori er til at finde i en kølemontre.`,
						video: null,
						recipe: null,
						image: null,
						funFact: ``,
						subtopics2: [
							{
								id: 1,
								name: 'Medisterpølse',
								text: 'Ordet medisterpølse kommer faktisk fra Tyskland. "Met" betyder svinekød, mens "ister" er betegnelsen for det fedt, der sidder omkring dyrets indvolde. Medisterpølse var en decideret middagspølse, og blev førhen spist til supper og kålretter. Som spisevanerne ændredes med komfuret, så holdt medisterpølsen skansen, men nu spises den typisk stegt og serveret med sovs og kartofler. Den er i dag en af de mest almindelige middagsretter og købes næsten altid fersk.',
								video: null,
								recipe: null,
								image: null,
								funFact: `Der produceres i Danmark årligt ca. 434.000 km tarme. Dvs. mere end 10 gange rundt om jorden.`
							},
							{
								id: 2,
								name: 'Hakket svinekød',
								text: 'Kødhakkeren blev opfundet i 1850. Før det, var det fornemme herskab, der spiste hakket kød, for det tog lang tid at hakke kød med en kniv.  <br/><br/>'+
								'Da kødhakkeren kom til gik det stærkt med at få frikadeller, kåldolmere og andre fars-retter på menuen.',
								video: require('assets/videos/Vidste_du_Frikadeller_1.mp4'),
								
								recipe: require('data/recipes/detail/hakkket-svinekød.md').default, 
								image: 'hakket_8_procent_fedt.jpg',
								funFact: null,
								cuttingInstructions: `Hakket kød laves af afskær og restkød fra grisen. Særligt på grisens bov, skank og skinke får man kødrester, som ikke indgår i andre udskæringer, og som kan kommes i kødhakkeren. Husk at tilsætte 7-8% fedt. Svinekød er i sig selv meget magert, så uden fedt i farsen kan det blive nogle tørre frikadeller.`,
								sustainability:'Stop madspild: <br/> <br/>'+
								'Hakket kød har en kort holdbarhed, så brug det straks eller frys det ned til senere brug.<br/> <br/>'+
								'Forklaring: <br/> <br/>'+
								'Efter slagtning er de hele stykker kød sterile i midten. Det er derfor kun på overfladen af kødet, at bakterier kan vokse, og hvis man opbevarer kødet korrekt på køl, så spreder bakterierne sig kun langsomt. Men når man hakker kød, så ødelægges den sterile kerne, og bakterierne kan pludselig vokse og formere sig hurtigere.  Derfor - brug det hakkede kød hurtigt.',
							},
						],
					},
					{
						id: 3,
						name: 'Canned',
						text: `Canned er den kategori i detailhandlen, der indeholder dåseprodukter såsom JAKA bov og cocktailpølser. Produkterne i denne kategori er til at finde på hylderne - typisk sammen med andre dåseprodukter.`,
						video: null,
						recipe: null,
						image: null,
						funFact: ``,
						subtopics2: [
							{
								id: 1,
								name: 'Cocktailpølser',
								text: 'Cocktailpølser er små pølser, der er gode at bruge i gryderetter eller på pizza. Du kan både købe cocktailpølser findes våde på køl og på dåse i de danske supermarkeder.',
								video: null,
								recipe: null,
								image: null,
								funFact: null,
							},
							{
								id: 2,
								name: 'JAKA bov',
								text: `JAKA boven - eller gris på dåse, som nogen kalder den - er lavet af hakket svinekød, ofte fra udskæringerne såsom skinke, bov, mørbrad, skank, kam og trimninger fra bov, skinke og brystflæsk. Dette røres sammen til en fars og krydres, hvorefter den kommes på dåse under vakuum. 

								I 1960'erne og 1970'erne var Jaka Bov en stor del af den kulinariske ånd i Danmark og  i 2010 kunne Politiken fortælle, at vi i Danmark spiser over 125 ton Jaka Bov om året.	`,
								video: null,
								recipe: null,
								image: 'Slagtere på JAKA-fabrikken.jpg', 
								image2: 'Jaka bov.jpg',
								funFact: 'I 2020 spiste danskerne hele 125 tons JAKA bov',
							},
						],
					},
					{
						id: 4,
						name: 'Processed',
						text: `Processed er den kategori af svinekød i detailhandlen, der indeholder bearbejdet kød såsom pølser. Produkterne i denne kategori er typisk til at finde i en kølemontre.`,
						video: null,
						recipe: null,
						image: null,
						funFact: ``,
						subtopics2: [
							{
								id: 1,
								name: 'Pizzatopping',
								text: `Denne kategor indeholder de forskellige produkter af pizzatopping, der kommer fra grisen. Det er bl.a. skinkestrimler, skinketern og pepperoni.  

								At fremstille pepperoni er en længerevarende proces, der begynder med en fars. Farsen består af bov, trimmings og fedt. Den blandes med krydderier og hjælpestoffer, hvorefter en fyldes i tarm. De rå pepperoni-pølser bliver derefter kvalitetssikret og ført ind i et fermenteringsrum, der har en konstant temperatur på 24 grader. Her hænger pølserne i 2-3 døgn, hvorefter de yderligere hænges til tørre i 2-5 uger inden de er klar til at blive pakket.`,
								video: null,
								recipe: null,
								image: null,
								funFact: null,
							},
							{
								id: 2,
								name: 'Pølser',
								text: `Pølser er en velkendt spise for de fleste danskere - og især hos "den traditionelle forbruger". Faktisk har hele 73% af danskerne grillet pølser af grisekød i løbet af 2021. At grille er dermed på en suveræn førsteplads af ting pølser bruges til blandt de danske forbrugere - stærkt efterfulgt af hotdogs og pølsehorn.

								Pølser i svinetarm tåler kraftigere varme og bruges derfor typisk til grillpølser.`,
								video: null,
								recipe: null,
								image: null, 
								funFact: 'Danskere i gennemsnit spiser 60 pølser om året.',
							},
							{
								id: 3,
								name: 'Pålæg',
								text: `Der findes et hav af forskellige pålæg i de danske supermarkeder og med god grund - hele 53% af voksne og 68% af børn spiser en medbragt madpakke til frokost i hverdagene.  

								De 5 mest populære kødpålægsprodukter blandt danskerne er leverpostejen, spegepølsen, hamburgerryggen, rullepølsen og kylling/kalkun.
								
								Vejen fra råvarer til friskbagt leverpostej tager kun tre timer. Processen starter med råvarer, som primært er grisespæk og griselever. Råvarerene koges og hakkes, hvorefter den ønskede krydderiblanding tilsættes. Herfra hakkes blandingen til en fars, der kan fyldes i bægere og køres ind i ovnen, hvor de bliver bagt i 22 minutter.`,
								video: null,
								recipe: null,
								image: null, 
								funFact: 'Den mest anvendte kødpålægstype i madpakken i Danmark er leverpostej.',
							},
							{
								id: 4,
								name: 'Bacon',
								text: `Bacon er en kødudskæring af grisens brystflæsk. Oftest spises der bacon til aftensmad og det er specielt populært at bruge det i retter med æg, såsom omelet eller scrambled eggs, eller i gryderetter og pastaretter. 

								Hele 51% af forbrugerne af bacon tilhører arketypen "de traditionelle".`,
								video: null,
								recipe: null,
								image: null, 
								funFact: 'Danskere spiser i gennemsnit over 100 stykker bacon om året.',
							},

							
						],
					},
					
					
				]
			},
			{
				id: 'international',
				name: 'International',
				image: 'img-international.svg',
				intro: 'Danmark sælger grisekød til hele verden. I 2020 omfattede salget i alt 127 lande. Samlet set eksporterede vi 1.915.088 tons grisekød til en værdi af 35.798 millioner kr. '+

				'Eksporten består af følgende: '+
				'<ul> '+
				' <li> Biprodukter </li>'+
				' <li> Levende grise </li>'+
				' <li> Tilberedt eller konserveret gris </li>'+
				' <li> Bacon </li>'+
				' <li> Hele og halve svin/søer </li>'+
				' <li> Udskæringer </li>'+
				' <li> Pølser </li>'+
				'</ul> ',
				introImage: null,
				funFact: `49,7 % af den samlede eksport af gris er til EU og 50,3% til 3.-lande.`,
				subtopics: [
					{
						id: 1,
						name: 'Amerika',
						text: `Danmark eksporterer over 14.000 tons svinekød indenfor processed kategorien og ca. 30.000 tons frossen svinekød til Amerika. 

						De største markeder for dansk svinekød i Amerika er henholdsvis Puerto Rico, Panama og USA.`
						,
						video: null,
						recipe: null,
						image: null,
						funFact: ``,
					},
					{
						id: 2,
						name: 'Europa',
						text: 'I 2020 bestod den danske svinkødseksport til Europa af i alt 952.567 tons. Ud af totalen bestod ca. 425 tusinde tons af levende grise og hele 375 tusinde tons af udskæringer. '+

						'I 2020 eksporterede Danmark omkring 13 tusinde tons biprodukter bestående af bl.a.: '+
						'<ul>'+
						'<li> Hjerte </li>'+
						'<li> Lunger</li>'+
						'<li> Lever</li>'+
						'<li> Tunge</li>'+
						'</ul>',
						video: null,
						recipe: null,
						image: null,
						funFact: `Tyskland er det land Danmark eksporterer mest grisekød til i hele Europa.`,
					},
					{
						id: 3,
						name: 'Afrika',
						text: `Der blev i 2020 eksporteret 1.365 tons grisekød til Sydafrika, hvilket gør det til det land der eksporteres mest grisekød til i hele Afrika. 

						Eksporten består hovedsagligt af grisehaler. De mest populære tilberedningsformer når man laver mad med grisehaler i Afrika er grill, stegning og sammenkogte retter.`
						,
						video: null,
						recipe: null,
						image: null,
						funFact: ``,
					},
					{
						id: 4,
						name: 'Asien og Oceanien',
						text: 'En stor del af den danske svinkødseksport går til lande i Asien og Oceanien. Det er bl.a. lande som Japan, Kina, Filippinerne og Papua Ny Guinea. '+

						'Til Kina eksporterer vi mange af grisens biprodukter. Det er dele af grisen så som:'+
						'<ul> '+
						'<li> Lunger </li>'+
						'<li> Haler</li>'+
						'<li> Tæer </li>'+
						'<li> Hoved</li>'+
						'<li> Ører </li>'+
						'</ul> '+
						'Griseørerene serveres typisk koldt med en sauce af chili, hvidløg, eddike og koriander.'
						,
						video: 'https://www.youtube.com/watch?v=iVSOaMKfBqA',
						recipe: null,
						image: null,
						funFact: `I Asien, bliver JAKA boven anset for at være et eksklusivt kvalitetsprodukt, som ofte gives i værtindegave.`,
					},
				
				]
			},
			{
				id: 'archetypes',
				name: 'Arketyperne',
				image: 'img-archetypes.svg',
				intro: `Ligesom med mange andre ting har forbrugere forskellige præferencer og ønsker, når det kommer til deres valg af dagligvarer. 

				De danske forbrugere kan deles op i forskellige typer - også kaldet for arketyper. Hver arketype er forskellig i deres tilgang til mad og indkøb, samt hvor meget fødevarer og mad fylder i deres liv.`,
				introImage: null,
				sustainability: `Hele 73% af befolkningen mener, det er vigtigt at bekæmpe klimaforandringer, men mange finder det rigtig svært at lave klimavenlig mad.`,
				funFact: `73 % af danskerne bruger mindre end 30 min. på at tilberede deres aftensmad.`,
				subtopics: [
					{
						id: 1,
						name: 'De traditionelle',
						text: 'Med 15% af befolkningen deler denne arketypen 3. pladsen sammen med de grønne.<br/> <br/>'+

						'De traditionelle er en arketype, der heller ikke er specielt mad- og fødevareinteresseret. De køber typisk supermarkedets egne billige produkter og giver ikke ekstra for kvalitetsprodukter. De traditionelle handler i fysiske butikker fremfor online og har heller ikke tendens til at gøre brug af hurtigretter eller udespisning.',
						video: null,
						recipe: null,
						image: null,
						funFact: `"De traditionelle" køber hovedsageligt danskproducerede fødevarer, når det er muligt.`
					},
					{
						id: 2,
						name: 'De umiddelbare',
						text: 'Den anden største gruppe af befolkningen (19%) tilhører arketype de umiddebare.<br/><br/>'+

						'Denne arketype adskiller sig fra de andre, ved at have en lav interesse for alt, der vedrører madlavning og madkvalitet. I stedet foretrækker de simpel mad og fastfood, for at minimere tiden brugt på madlavning. Det er derfor typisk for denne arketype at købe supermarkedets egne billige private labels når de er ude at handle.',
						video: null,
						recipe: null,
						image: null,
						funFact: `De umiddelbare bruger i gennemsnit 5.000 kr. mindre på mad om året end gennemsnittet.`
					},
					{
						id: 3,
						name: 'De grønne',
						text: 'På en delt 3. plads, med hele 15% af befolkningen, finder vi arketypen "De grønne". <br/><br/>'+

						'De grønne er bæredygtige i deres holdning til forbrug. De handler ofte i genbrugsbutikker og deltager i velgørende arrangementer. Deres mærkesag er ubetinget bæredygtighed, da de har en stor interesse i klima, dyrevelfærd og miljø – og de søger derfor måder hvorpå de kan formindske deres eget CO2 forbrug. På en almindelig uge laver hele 65% af de grønne vegetarretter minimum en gang.',
						video: null,
						recipe: null,
						image: null,
						funFact: `Danskere tilhørende denne arketype er hovedsageligt studerende.`,
						sustainability: 'For at leve mere bæredygtigt gør danskerne følgende: <br/> <br/>'+
						'Forsøger at undgå madspild, spiser frugt og grønt, der er i sæson, sorterer madaffald fra øvrigt affald og køber lokalt fremfor importerede varer.'
					},
					{
						id: 4,
						name: 'De bekvemme',
						text: 'Med hele 26% af befolkningen er de bekvemme den største gruppe indenfor arketyperne. Arketypen adskiller sig meget fra de andre med deres store behov for underholdning. De bekvemme vil altså hellere bruge deres tid på oplevelser fremfor på madlavning. <br/> <br/>'+

						'Mad og fødevarer generelt er ikke et fokus for de bekvemme, og de søger derfor nemme alternativer i form af nemme madlavningsløsninger og spisning ude.',
						video: null,
						recipe: null,
						image: null,
						funFact: ``
					}
				]
			},
		],
	},
];

export default exploreData;