const themesData = [
	// {
	// 	id: 1,
	// 	name: 'Livet som slagter',
	// 	intro: 'Bliv klogere på en slagters hverdag og lær om alt fra slagterens påklædningskrav til de maskiner han bruger.',
	// },
	// {
	// 	id: 2,
	// 	name: 'Gris på bordet',
	// 	intro: 'Bliv klogere på, hvordan vi får gris på bordet i Danmark. Lær om, hvad der sker på et slagteri, det udstyr man bruger og ikke mindst om grisens udskæringer.',
	// },
	// {
	// 	id: 3,
	// 	name: 'Fra pattegris til slagtegris',
	// 	intro: 'Bliv klogere på, hvor grisen kommer fra og lidt om landsmandslivet.',
	// },

	{
		id: 9, // combi of 2 and 3
		name: 'Rundt om grisen',
		intro: `Bliv klogere på hvad vi bruger grisen til og hvordan den ender på vores spisebord.`
	},
	// {
	// 	id: 4,
	// 	name: 'En bæredygtig fremtid for fødevarer',
	// 	intro: 'Bliv klogere på, slagteriets udvikling gennem tiden og hvordan de arbejder på at blive mere bæredygtige.',
	// },
	{
		id: 5,
		name: 'Kender du typen?',
		intro: `Bliv klogere på de danske forbrugere og deres madvaner. Lær om hygiejne, vildsvin og hvem der pudser grisens fedtende.`,
	},
	{
		id: 6,
		name: 'Grisen - et verdensdyr',
		intro: `Bliv klogere på hvor i  verden Danmark eksporterer grise til. Lær om hvilke udskæringer af grisen der anses for at være en delikatesse og lidt om hvor tamgrisen stammer fra.`,
	},
	{
		id: 7,
		name: 'Grisen - en tidsrejse',
		intro: 'Bliv klogere på grisen i et historisk perspektiv og lær om alt fra frikadellens indtog i kogebøgerne til antallet af pølsevogne i Danmark i 1970\'erne.',
	},
	// {
	// 	id: 8,
	// 	name: 'Jobs rundt om grisen',
	// 	intro: 'Bliv klogere på de forskellige jobs på slagteriet og hvordan deres hverdag ser ud. Lær om alt fra en slagterens løn til hvordan man uddanner sig til tarmrenser.',
	// },

];

export default themesData;