/* TEMPLATE
{
	id: 'order-1',
	type: 'order',
	themeId: null,
	hint: {
		location: '',
		topic: '',
		subtopic: 0,
		popup: '',
		isInfoBoxText: false
	},
	challengeNumber: null,
	question: {
		text: 'Der er opstået dyregrupper både før og efter dinoerne. Tryk på et dyr og et sted på tidslinjen for at placere dyregrupperne i den rækkefølge, de opstod.',
	},
	boxes: [
		{id: 1, name: 1, placedItemId: null, correctItemId: 'insects'},
		{id: 2, name: 2, placedItemId: null, correctItemId: 'sharks'},
		{id: 3, name: 3, placedItemId: null, correctItemId: 'dinosaurs'},
		{id: 4, name: 4, placedItemId: null, correctItemId: 'mammals'},
		{id: 5, name: 5, placedItemId: null, correctItemId: 'birds'},
		{id: 6, name: 6, placedItemId: null, correctItemId: 'crocodiles'},
		{id: 7, name: 7, placedItemId: null, correctItemId: 'whales'},
		{id: 8, name: 8, placedItemId: null, correctItemId: 'humans'},
	],
	items: [
		{id: 'insects', name: 'Insekter'},
		{id: 'sharks', name: 'Hajer'},
		{id: 'dinosaurs', name: 'Dinosaurer'},
		{id: 'mammals', name: 'Pattedyr'},
		{id: 'birds', name: 'Fugle'},
		{id: 'crocodiles', name: 'Krokodiller'},
		{id: 'whales', name: 'Hvaler'},
		{id: 'humans', name: 'Det moderne menneske'},
	]
}
*/

const orderData = [
	// {
	// 	id: 'order-1',
	// 	type: 'order',
	// 	themeId: 1,
	// 	challengeNumber: 1,
	// 	hint: {
	// 		location: 'butchery',
	// 		topic: 'butcher',
	// 		subtopic: 2,
	// 		subtopic2: 2,
	// 		popup: 'video',
	// 		isInfoBoxText: true
	// 	},
	// 	question: {
	// 		text: 'Hvordan klæder man sig korrekt på inden man træder ud i produktionsområdet på slagteriet?',
	// 	},
	// 	boxes: [
	// 		{id: 1, name: 1, placedItemId: null, correctItemId: 'hairnet'},
	// 		{id: 2, name: 2, placedItemId: null, correctItemId: ['hand-desinfection', 'hand-desinfection2']},
	// 		{id: 3, name: 3, placedItemId: null, correctItemId: 'pants'},
	// 		{id: 4, name: 4, placedItemId: null, correctItemId: 'shoes'},
	// 		{id: 5, name: 5, placedItemId: null, correctItemId: ['hand-desinfection', 'hand-desinfection2']},
	// 	],
	// 	items: [
	// 		{id: 'hand-desinfection', name: 'Afsprit hænder'},
	// 		{id: 'pants', name: 'Bukser og kittel'},
	// 		{id: 'shoes', name: 'Sko eller støvler'},
	// 		{id: 'hairnet', name: 'Hårnet og skægbind'},
	// 		{id: 'hand-desinfection2', name: 'Afsprit hænder'},
	// 	]
	// },
	// {
	// 	id: 'order-2',
	// 	type: 'order',
	// 	themeId: 1,
	// 	challengeNumber: 11,
	// 	hint: {
	// 		location: 'butchery',
	// 		topic: 'engine-room',
	// 		subtopic: null,
	// 		subtopic2: null,
	// 		popup: null,
	// 		isInfoBoxText: true,
	// 		isFunFact: false
	// 	},
	// 	question: {
	// 		text: 'hvilken rækkefølge bliver disse tre maskiner brugt?',
	// 	},
	// 	boxes: [
	// 		{id: 1, name: 1, placedItemId: null, correctItemId: 'robotsav'},
	// 		{id: 2, name: 2, placedItemId: null, correctItemId: 'tre-deler-robot'},
	// 		{id: 3, name: 3, placedItemId: null, correctItemId: 'bovbladsmaskine'},
	// 	],
	// 	items: [
	// 		{id: 'robotsav', name: 'Robotsav'},
	// 		{id: 'tre-deler-robot', name: 'Tre-deler-robot'},
	// 		{id: 'bovbladsmaskine', name: 'Bovbladsmaskine'},
	// 	]
	// },
	{
		id: 'order-3',
		type: 'order',
		// themeId: 3,
		themeId: 9,
		challengeNumber: 11,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 1,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvornår er grisen i hvilken stald?',
		},
		boxes: [
			{id: 1, name: 1, placedItemId: null, correctItemId: 'farestald'},
			{id: 2, name: 2, placedItemId: null, correctItemId: 'piglet-barn'},
			{id: 3, name: 3, placedItemId: null, correctItemId: 'slaughter-barn'},
		],
		items: [
			{id: 'farestald', name: 'Farestald'},
			{id: 'piglet-barn', name: 'Smågrisestald'},
			{id: 'slaughter-barn', name: 'Slagtesvinestald'},
		]
	}
];

export {
	orderData
};