import React from 'react';
import {generalUiTexts} from 'data/ui-texts';
import './loading.scss';

const Loading = () => {

	const loadingText = generalUiTexts.loadingGame;
	return (
		<div className="Loading">
			<div className="Loading-content">
				<div className="Loading-circles">
					<div className="Loading-circle" />
					<div className="Loading-circle" />
					<div className="Loading-circle" />
				</div>
				<div className="Loading-text">{loadingText}</div>
			</div>
		</div>
	);
};

export default Loading;
