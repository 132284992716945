import React from 'react';
import firebaseInit from 'firebase-init';
import appConfig from 'config/app.config';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import {getScreenSize, getDeviceInfo, isMobileOrTablet} from 'helpers/device-helper';
import {checkIfFullscreen, toggleFullscreen} from 'helpers/fullscreen-helper';
import CookieConsent from 'components/cookie-consent/cookie-consent';
import LandingPage from './landing-page/landing-page';
import StudentController from 'components/users/student-controller';
// import ImageLoader from 'components/image-loader/image-loader';
import './app.scss';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showGame: false,
			showCookiePopup: false,
			cookiesAccepted: false,
			isInStandaloneMode: false,
			isTouchScreen: false,
			isMobile: false,
			isIos: false,
			isFullscreen: false,
			fullscreenAllowed: true,
			screenSize: {width: 0, height: 0},
			orientation: 'landscape',
			viewMode: 'landscape'
		};
		this.unsubscribe = null;
		this.componentDidMount = this.componentDidMount.bind(this);
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.handleToggleFullscreen = this.handleToggleFullscreen.bind(this);
		this.updateFullscreenStatus = this.updateFullscreenStatus.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Initialize firestore */
		firebaseInit();

		/* Check cookie consent */
		const cookieData = getCookie(appConfig.cookieDataName);

		/* Check if the device is run in stand alone mode */
		let isInStandaloneMode = 
		window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;

		/* Get device info */
		let [isTouchScreen, isMobile, isIos, screenSize, orientation] = getDeviceInfo();

		/* Viewmode depends on device */
		// let viewMode = (isTouchScreen ? 'portrait' : 'landscape');
		let viewMode = (isMobileOrTablet() ? 'portrait' : 'landscape');
		

		/* Landscape specific style */
		if (viewMode === 'landscape') document.body.style.overflow = 'hidden';

		/* Enable auto fullscreen on navigation */
		let fullscreenAllowed = (
			viewMode === 'landscape'
			|| (isMobile && !isIos && appConfig.env !== 'development')
		);

		/* Update state */
		this.setState({
			isLoading: false,
			showCookiePopup: (cookieData ? false : true),
			cookiesAccepted: (cookieData ? true : false),
			isInStandaloneMode: isInStandaloneMode,
			isTouchScreen: isTouchScreen,
			isMobile: isMobile,
			isIos: isIos,
			screenSize: screenSize,
			orientation: orientation,
			viewMode: viewMode,
			isFullscreen: checkIfFullscreen(),
			fullscreenAllowed: fullscreenAllowed
		});

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', this.handleWindowResize, false);

		/* Add event listener for fullscreen status */
		if (fullscreenAllowed && document.addEventListener) {
			document.addEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('fullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
		}
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		document.removeEventListener('resize', this.handleWindowResize, false);
		document.removeEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('fullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
	}	

	/**
	 * Confirm cookies
	 */
	handleConfirmCookies = () => {
		setCookie(appConfig.cookieDataName, JSON.stringify({userId: null}));
		this.setState({showCookiePopup: false, cookiesAccepted: true});
	};

	/**
	 * Start game
	 */
	handleStartGame = () => {
		if (!this.state.cookiesAccepted) return;

		this.setState({showGame: true});
	};

	handleExitGame = () => {
		this.setState({showGame: false});
	};

	/**
	 * User resized window
	 */
	handleWindowResize() {
		let [screenSize, orientation] = getScreenSize();
		this.setState({screenSize: screenSize, orientation: orientation});
	}

	/**
	 * Toggle full screen
	 */
	handleToggleFullscreen() {
		let isFullscreen = checkIfFullscreen();
		toggleFullscreen(!isFullscreen);
		this.setState({isFullscreen: !isFullscreen});
	}
	updateFullscreenStatus() {
		let isFullscreen = checkIfFullscreen();
		this.setState({isFullscreen: isFullscreen});
	}
	

	render = () => {
		/* Game page */
		if (this.state.showGame) {
			return (
				<div id="app" className={'App App--' + this.state.orientation
				+ ' App--' + (isMobileOrTablet() ? 'mobileTablet' : '')}>
					<div id="appWrap" className="App-wrap">
						<StudentController handleExitGame={this.handleExitGame} />
					</div>
				</div>
			);
		}

		/* Landing page */
		return (
			<div id="app" className={'App App--' + this.state.orientation
				+ ' App--' + (isMobileOrTablet() ? 'mobileTablet' : '')}>
				<div id="appWrap" className="App-wrap">
					<LandingPage cookiesAccepted={this.state.cookiesAccepted} handleStartGame={this.handleStartGame} />
					{this.state.showCookiePopup && 
						<CookieConsent 
							handleConfirmCookies = {this.handleConfirmCookies}
						/>
					}
				</div>
			</div>
		);
	};
}

export default App;
