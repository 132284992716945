import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import { ReactComponent as PigImage } from 'assets/images/the-pig.svg';
import Button from 'components/button/button';
import './landing-page.scss';

const LandingPage = ({cookiesAccepted, handleStartGame}) => {


	return (
		<div className="LandingPage">
			<div className="LandingPage-wrap">
				<h2 className="LandingPage-title">{generalUiTexts.title}</h2>
				<PigImage className="PigImageLogin"/>
				{cookiesAccepted && <div className="LandingPage-startBtn">
					<Button 
						classes={['start']}
						text={generalUiTexts.start}
						onClick={() => {handleStartGame();}}
					/>
				</div>}				
			</div>
			<div className='LandingPage-logo-wrap'>
				<div className='LandingPage-LF-logo'/>
				<div className='LandingPage-cgl-logo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
			</div>
			
		</div>
	);
};

LandingPage.propTypes = {
	cookiesAccepted: PropTypes.bool.isRequired,
	handleStartGame: PropTypes.func.isRequired
};

export default LandingPage;