import React, {Component} from 'react';
import PropTypes from 'prop-types';
import themesData from 'data/themes-data';
import {quizzesData} from 'data/quizzes-data';
import {orderData} from 'data/order-data';
import {sortData} from 'data/sort-data';
import {pairsData} from 'data/pairs-data';
import {sortArrayByProperty} from 'helpers/array-helper';
import {isThemeCompleted, getChallengesStatus} from 'helpers/progress-helper';
import Theme from './theme';

class ThemeController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showThemeIntroContent: true,
			showThemeCompletedContent: false,
			themeData: null,
			challengesData: [],
			themeChallengesData: [],
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Get theme data */
		let themeData = null;
		if (themesData.some((theme) => {return theme.id === this.props.themeId;})) {
			themeData = themesData.filter((theme) => {return theme.id === this.props.themeId;})[0];
		}

		/* Get challenges of theme */
		let challengesData = JSON.parse(JSON.stringify([...quizzesData, ...orderData, ...sortData, ...pairsData]));
		let themeChallengesData = challengesData.filter((challengeData) => {
			return (
				challengeData.themeId === this.props.themeId &&
				challengeData.challengeNumber && challengeData.challengeNumber >= 0
			);
		});
		themeChallengesData = sortArrayByProperty(themeChallengesData, 'challengeNumber', 'ASC');

		/* Theme intro content */
		let showThemeIntroContent = this.state.showThemeIntroContent;

		/* Check if theme is completed */
		let showThemeCompletedContent = false;
		if (
			isThemeCompleted(themeChallengesData, this.props.playerData)
		) {
			showThemeCompletedContent = true;
			showThemeIntroContent = false;
		};

		/* Update state */
		this.setState({
			themeData,
			challengesData,
			themeChallengesData,
			showThemeCompletedContent,
			showThemeIntroContent,
			isLoading: false,
		});
	};

	/**
	 * Complete theme or all themes
	 */
	handleThemeComplete = () => {
		let numberOfCompletedThemes = 0;
		themesData.forEach((theme) => {
			let themeIsCompleted = false;

			if (theme.id === this.props.themeId) {
				/* Current theme */
				themeIsCompleted = true;
			} else {
				/* Other theme */
				let themeChallengesData = this.state.challengesData.filter((challenge) => {
					return challenge.themeId === theme.id;
				});
				themeIsCompleted = isThemeCompleted(themeChallengesData, this.props.playerData);
			}
			if (themeIsCompleted) numberOfCompletedThemes = numberOfCompletedThemes + 1;			
		});


		/* Theme completed */
		this.setState({showThemeCompletedContent: true});
		window.scrollTo(0, 0);

		/* Remove hint animations */
		this.props.setHint(null);
	};

	startTheme = () => {
		this.setState({showThemeIntroContent: false});
	};

	resetTheme = () => {
		let playerChallenges = this.props.playerData.challenges.filter((challenge) => {
			return !this.state.themeChallengesData.some((c) => {return c.id === challenge.id;});
		});

		let playerThemePoints = this.props.playerData.challenges.filter((challenge) => {
			return this.state.themeChallengesData.some((c) => {return c.id === challenge.id;});
		}).reduce((prev, curr) => {return curr.points ? prev + curr.points : prev;}, 0);

		let points = this.props.playerData.points - playerThemePoints;

		this.props.updatePlayerData({challenges: playerChallenges, points: points, completed: null}).then(() => {
			this.setState({
				showThemeCompletedContent: false,
				showThemeIntroContent: false
			});
		});
	};

	/**
	 * Render component
	 */
	render = () => {
		if (this.state.themeData) {
			let themeChallengesData = getChallengesStatus(this.state.themeChallengesData, this.props.playerData);
			return (
				<Theme
					showThemeIntroContent={this.state.showThemeIntroContent}
					showThemeCompletedContent={this.state.showThemeCompletedContent}
					themeData={this.state.themeData}
					themeChallengesData={themeChallengesData}
					playerData={this.props.playerData}
					goToTheme={this.props.goToTheme}
					handleThemeComplete={this.handleThemeComplete}
					updatePlayerData={this.props.updatePlayerData}
					startTheme={this.startTheme}
					resetTheme={this.resetTheme}
					showHint={this.props.showHint}
					setHint={this.props.setHint}
				/>
			);
		}
		return null;
	};
}

ThemeController.propTypes = {
	themeId: PropTypes.number.isRequired,
	goToTheme: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	showHint: PropTypes.func.isRequired,
	setHint: PropTypes.func.isRequired,
};

export default ThemeController;