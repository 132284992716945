import React from 'react';
import PropTypes from 'prop-types';
import './page.scss';

const Page = (props) => {
	return (
		<div className={'Page Page--student'}>
			{props.children}
		</div>
	);
};

Page.propTypes = {
	children: PropTypes.any.isRequired,
};

export default Page;