import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAZq1jjsh4a5XQbfRFVhHxZ4YGTK2JXUJI',
		authDomain: 'cgl-digitalgrislight-test.firebaseapp.com',
		projectId: 'cgl-digitalgrislight-test',
		storageBucket: 'cgl-digitalgrislight-test.appspot.com',
		messagingSenderId: '9858464827',
		appId: '1:9858464827:web:35b879cb71b837b71c301e'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyC9SYKJiUb-oamNNCNxLZW8DcPnJNkp1Xk',
		authDomain: 'cgl-digitalgrislight-pro.firebaseapp.com',
		projectId: 'cgl-digitalgrislight-pro',
		storageBucket: 'cgl-digitalgrislight-pro.appspot.com',
		messagingSenderId: '663166745895',
		appId: '1:663166745895:web:d6eb12dbde13c251e3fd02',
		measurementId: 'G-GP1Q42YXZF'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize analytics */
if (env === 'production') {
	firebase.analytics();
}

export default firebase.firestore;