import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {quizPoints} from 'data/points-data';
// import {challengeUiTexts} from 'data/ui-texts';
import Quiz from './quiz';

class QuizController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPaused: false,
			playerHasSeenClueWarning: false,
			showConfirmGetCluePopup: false,
			showImagePopup: false,
			quizData: null,
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Load challenge */
		this.loadChallenge();
	};

	/**
	 * Component updated
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (this.props.challengeData.id !== prevProps.challengeData.id) {
			this.setState({isLoading: true, quizData: null}, () => {this.loadChallenge();});
		}
	};

	/**
	 * Load quiz & sync with player data
	 */
	loadChallenge = () => {
		/* Get quiz data */
		let challengeData = JSON.parse(JSON.stringify(this.props.challengeData));
		challengeData.completed = false;

		/* Shuffle answers */
		challengeData.answers = shuffleArray(challengeData.answers);

		/* Sync with player progress */
		if (
			this.props.playerData.hasOwnProperty('challenges') &&
				this.props.playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
		) {
			let playerChallengeData = this.props.playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				challengeData.completed = playerChallengeData.completed;
			}
			challengeData.answers.forEach((answer) => {
				if (playerChallengeData.selectedAnswers.indexOf(answer.id) >= 0) answer.isSelected = true;
			});

			let revealedClues = 0;
			if (
				challengeData.clues && challengeData.clues.length > 0 && 
				playerChallengeData.hasOwnProperty('clues')
			) {
				revealedClues = playerChallengeData.clues;
			}
			if (revealedClues > 0) {
				challengeData.clues.forEach((clue, index) => {
					if (index < revealedClues) clue.isRevealed = true;
				});
			}			
		}

		/* Update state */
		this.setState({isLoading: false, isPaused: challengeData.completed, quizData: challengeData}, () => {
			/* Adjust body height */
			// let headerElement = document.getElementById('QuizHeader');
			// let bodyElement = document.getElementById('QuizBody');
			// if (headerElement && bodyElement) {
			// 	let fontSize = 16 * ((headerElement.clientWidth / 360));
			// 	let headerHeight = headerElement.clientHeight + (0.1 * fontSize);
			// 	bodyElement.style.paddingTop = headerHeight + 'px';
			// }
		});
	};

	/**
	 * Show / hide clue popup
	 * @param {bool} showConfirmGetCluePopup
	 */
	toggleCluePopup = (showConfirmGetCluePopup) => {
		this.setState({showConfirmGetCluePopup: showConfirmGetCluePopup});
	};

	/**
	 * Show / hide image popup
	 */
	toggleImagePopup = (showImagePopup) => {
		this.setState({showImagePopup: showImagePopup});
	};

	/**
	 * Get next clue
	 */
	handleGetClue = () => {
		/* Update quiz data */
		let quizData = JSON.parse(JSON.stringify(this.state.quizData));
		let nextClueIndex = quizData.clues.findIndex((clue) => {return !clue.isRevealed;});
		quizData.clues[nextClueIndex].isRevealed = true;
				
		/* Update player data */
		let revealedClues = quizData.clues.filter((clue) => {return clue.isRevealed;}).length;
		let playerChallenges = [];
		if (this.props.playerData.hasOwnProperty('challenges')) {
			playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
		}
		let playerChallengeIndex = playerChallenges.findIndex((challenge) => {return challenge.id === quizData.id;});
		if (playerChallengeIndex === -1) {
			playerChallenges.push({id: quizData.id, completed: false, clues: revealedClues, selectedAnswers: []});
		} else {
			playerChallenges[playerChallengeIndex].clues = revealedClues;
		}
		
		this.props.updatePlayerData({challenges: playerChallenges}).then((response) => {
			if (response.status === 'ok') {
				this.setState({
					playerHasSeenClueWarning: true,
					showConfirmGetCluePopup: false,
					quizData: quizData
				}, () => {this.adjustBodyHeight();});
			} else {
				console.error('error');
			}
		});
	};

	/**
	 * Select answer
	 * @param {number} answerId
	 */
	handleSelectAnswer = (answerId) => {
		if (this.state.isPaused) return;
		
		this.setState({isPaused: true}, () => {
			/* Update quiz data */
			let quizData = JSON.parse(JSON.stringify(this.state.quizData));
			let answerIndex = quizData.answers.findIndex((answer) => {return answer.id === answerId;});
			quizData.answers[answerIndex].isSelected = true;
			this.setState({quizData: quizData});
			
			/* Player data - progress */
			let errors = 0;
			quizData.answers.forEach((answer) => {
				if (!answer.isCorrect && answer.isSelected) errors = errors + 1;
			});

			let selectedAnswers = [];
			quizData.answers.forEach((answer) => {
				if (answer.isSelected) selectedAnswers.push(answer.id);
			});

			let playerChallenges = [];
			if (this.props.playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
			}
			let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
				return challenge.id === quizData.id;
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: quizData.id, completed: false, clues: 0, errors: errors, selectedAnswers: selectedAnswers
				});
			} else {
				playerChallenges[playerChallengeIndex].selectedAnswers = selectedAnswers;
				playerChallenges[playerChallengeIndex].errors = errors;
			}

			/* Player data - points */
			let playerPoints = this.props.playerData.hasOwnProperty('points') 
				? JSON.parse(JSON.stringify(this.props.playerData.points))
				: 0;

			/* Update player data */
			this.props.updatePlayerData({
				challenges: playerChallenges,
				points: playerPoints
			}).then((response) => {
				if (response.status === 'ok') {
					/* Check if all correct answers have been selected */
					let challengeIsComplete = 
						quizData.answers.filter((answer) => {
							return (answer.isCorrect && answer.isSelected);
						}).length ===
						quizData.answers.filter((answer) => {return answer.isCorrect;}).length;

					if (challengeIsComplete) {
						/* Update game state, handle challenge completed */
						this.timeout = setTimeout(() => {
							this.handleCompleteQuiz();
						}, 250);
					} else {
						/* Update game state, show streak popup */
						this.setState({quizData: quizData, isPaused: false});
					}
				} else {
					console.error('error', response);
				}
			});
		});
	};

	/**
	 * Complete quiz
	 * Check answer(s), give feedback, update player data
	 */
	handleCompleteQuiz = () => {
		let quizData = JSON.parse(JSON.stringify(this.state.quizData));
		quizData.completed = true;

		/* Calculate points */
		let basePoints = quizPoints.basePoints;

		let wrongAnswers = 0;
		quizData.answers.forEach((answer) => {
			if (!answer.isCorrect && answer.isSelected) wrongAnswers = wrongAnswers + 1;
		});

		let clues = 0;
		if (quizData.clues && quizData.clues.length > 0) {
			basePoints = quizPoints.basePointsWithClues;
			let playerChallengeIndex = this.props.playerData.challenges.findIndex((challenge) => {
				return challenge.id === quizData.id;
			});
			if (playerChallengeIndex >= 0) {
				let playerChallengeData = this.props.playerData.challenges[playerChallengeIndex];
				let revealedClues = (playerChallengeData.hasOwnProperty('clues')
					? playerChallengeData.clues
					: 0
				);
				let firstClueIsFree = (quizData.hasOwnProperty('firstClueIsFree') && quizData.firstClueIsFree === true);
				clues = (revealedClues > 0 && firstClueIsFree ? revealedClues - 1 : revealedClues); 
			}
		}

		let points = Math.max(
			quizPoints.minPoints,
			basePoints - (wrongAnswers * quizPoints.minusPointsPerWrongAnswer + clues * quizPoints.minusPointsPerClues)
		);
	
		/* Update player data */
		this.props.completeChallenge(quizData.id, points).then(() => {
			this.setState({quizData: quizData});
		});
	};


	render = () => {
		if (!this.state.isLoading && this.state.quizData) {
			return (
				<Quiz
					isPaused={this.state.isPaused} 
					playerHasSeenClueWarning={this.state.playerHasSeenClueWarning}
					showConfirmGetCluePopup={this.state.showConfirmGetCluePopup}
					showImagePopup={this.state.showImagePopup}
					clueIndex={this.state.clueIndex}
					quizData={this.state.quizData} 
					toggleCluePopup={this.toggleCluePopup}
					toggleImagePopup={this.toggleImagePopup}
					handleGetClue={this.handleGetClue}
					handleSelectAnswer={this.handleSelectAnswer}
				/>
			);
		}
		return null;
	};
}

QuizController.propTypes = {
	challengeData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	completeChallenge: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired
};

export default QuizController;