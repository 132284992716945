import React from 'react';
import PropTypes from 'prop-types';
import {cookieUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './cookie-consent.scss';

const CookieConsent = ({handleConfirmCookies}) => {
	return (
		<div className="CookieConsent">
			<div className="CookieConsent-text">
				<span>{cookieUiTexts.text + ' '}</span>
			</div>
			<div className="CookieConsent-button">
				<Button
					classes={['cookies']}
					text={cookieUiTexts.btn}
					onClick={handleConfirmCookies}
				/>
			</div>
		</div>
	);	
};

CookieConsent.propTypes = {
	handleConfirmCookies: PropTypes.func.isRequired
};

export default CookieConsent;