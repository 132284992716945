import React from 'react';
import PropTypes from 'prop-types';
import Explore from './explore';
import exploreData from 'data/explore-data';

class ExploreController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location: null,
			topic: null,
			subtopic: null,
			subtopic2: null,
			popup: {
				type: '',
				show: false
			},
			freezeScreen: false
		};
		this.infoBoxRef = React.createRef();
		this.recipePopupRef = React.createRef();
	}

	componentDidMount() {
		let location = exploreData.find((l) => {return l.id === 'butchery';});
		this.setState({location});
	}

	selectLocation = (location) => {
		this.setState({
			location,
			topic: null,
			subtopic: null,
			subtopic2: null,
			popup: {
				type: '',
				show: false
			}
		});
	};

	selectTopic = (topic) => {
		if (this.infoBoxRef.current) this.infoBoxRef.current.scrollTop = 0;
		this.setState({
			topic: topic,
			subtopic: null,
			subtopic2: null,
			popup: {
				type: '',
				show: false
			},
			freezeScreen: true
		}, () => {
			setTimeout(() => {
				this.setState({freezeScreen: false});
			}, 1000);
		});
	};

	selectSubtopic = (subtopic) => {
		if (this.infoBoxRef.current) this.infoBoxRef.current.scrollTop = 0;
		this.setState({
			subtopic: subtopic,
			subtopic2: null,
			popup: {
				type: '',
				show: false
			}
		});
	};

	selectSubtopic2 = (subtopic2) => {
		if (this.infoBoxRef.current) this.infoBoxRef.current.scrollTop = 0;
		this.setState({
			subtopic2,
			popup: {
				type: '',
				show: false
			}
		});
	};

	togglePopup = (type, show) => {
		if (this.recipePopupRef.current && show) this.recipePopupRef.current.scrollTop = 0;
		this.setState({
			popup: {
				type,
				show
			}
		});
	};

	/* Render component */
	render() {
		if (this.state.location) {
			return (
				<Explore
					location={this.state.location}
					topic={this.state.topic}
					subtopic={this.state.subtopic}
					subtopic2={this.state.subtopic2}
					selectLocation={this.selectLocation}
					selectTopic={this.selectTopic}
					selectSubtopic={this.selectSubtopic}
					selectSubtopic2={this.selectSubtopic2}
					togglePopup={this.togglePopup}
					popup={this.state.popup}
					hint={this.props.hint}
					freezeScreen={this.state.freezeScreen}
					infoBoxRef={this.infoBoxRef}
					recipePopupRef={this.recipePopupRef}
				/>
			);
		}
		return null;
	}
}

ExploreController.propTypes = {
	hint: PropTypes.object
};

export default ExploreController;