import {marked} from 'marked';

/**
 * Parse markdown text
 * @param {string} text
 * @return {html}
 */
export function renderMarkdown(text) {
	if (text && typeof text === 'string' && text.length > 0) {
		const html = marked.parse(text);
		return <div dangerouslySetInnerHTML={{__html: html}} />;
	}
	return <div />;
}