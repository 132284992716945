import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Challenge from './challenge';
import {getChallengesStatus} from 'helpers/progress-helper';

class ChallengeController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			challengeCompleted: false,
			challengeIndex: 0,
			challengesData: [],
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get index of first un-completed challenge */
		let challengeIndex = 0;
		let endLoop = false;
		if (this.props.playerData.hasOwnProperty('challenges') && this.props.playerData.challenges.length > 0) {
			this.props.themeChallengesData.forEach((challengeData, index) => {
				if (endLoop) return;
				let playerChallengeIndex = this.props.playerData.challenges.findIndex((challenge) => {
					return challenge.id === challengeData.id;
				});
				/* First un-competed challenge */
				if (playerChallengeIndex < 0 || !this.props.playerData.challenges[playerChallengeIndex].completed) {
					challengeIndex = index;
					endLoop = true;
				}

				/* End of loop, all challenges are completed */
				if (index === this.props.themeChallengesData.length - 1) {
					challengeIndex = this.props.themeChallengesData.length - 1;
				}
			});
		}

		/* Show hint */
		let hint = this.props.themeChallengesData[challengeIndex].hint;
		if (hint) this.props.setHint(hint);
		
		/* Update state, finish loading */
		this.setState({isLoading: false, challengeIndex});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.timeout) clearTimeout(this.timeout);
	};

	/**
	 * Navigate to specific challenge in current theme
	 * @param {number} challengeIndex
	 */
	goToChallenge = (challengeIndex) => {
		this.setState({
			challengeIndex: challengeIndex,
			challengeCompleted: false,
		});
		window.scrollTo(0, 0);

		/* Show hint */
		let hint = this.props.themeChallengesData[challengeIndex].hint;
		if (hint) this.props.setHint(hint);
	};

	/**
	 * Navigate to the next challenge in current theme
	 */
	goToNextChallenge = () => {
		if (this.state.challengeIndex < this.props.themeChallengesData.length - 1) {
			this.goToChallenge(this.state.challengeIndex + 1);
		}
	};

	/**
	 * Complete challenge
	 * @param {string} challengeId
	 * @param {number} points
	 * @param {bool} streakUnlocked
	 */
	completeChallenge = (challengeId, points) => {
		let challengeCompleted = true;
		
		/* Update challenge data */
		let playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
		let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
			return challenge.id === challengeId;
		});
		playerChallenges[playerChallengeIndex].completed = true;
		playerChallenges[playerChallengeIndex].points = points;

		/* Update total points */
		let totalPoints = (this.props.playerData.hasOwnProperty('points') ? this.props.playerData.points : 0);
		totalPoints = totalPoints + points;

		/* Show next button */
		this.timeout = setTimeout(() => {
			this.setState({
				challengeCompleted,
			});
			/* Get status of each challenge (ready / completed / failed) */
			let themeChallengesData = getChallengesStatus(this.props.themeChallengesData, this.props.playerData);
			let isLastChallengeInTheme = (this.state.challengeIndex >= themeChallengesData.length - 1);
			isLastChallengeInTheme ? this.props.handleThemeComplete() : this.goToNextChallenge();
		}, 2000);

		/* Update player data */
		return this.props.updatePlayerData({challenges: playerChallenges, points: totalPoints});
	};

	/**
	 * Render component
	 */
	render = () => {
		if (this.state.isLoading) return null;

		/* Get status of each challenge (ready / completed / failed) */
		let themeChallengesData = getChallengesStatus(this.props.themeChallengesData, this.props.playerData);

		let isLastChallengeInTheme = (this.state.challengeIndex >= themeChallengesData.length - 1);

		return (
			<Challenge
				challengeCompleted={this.state.challengeCompleted}
				isLastChallengeInTheme={isLastChallengeInTheme}
				themeData={this.props.themeData}
				themeId={this.props.themeData.id}
				challengeIndex={this.state.challengeIndex}
				themeChallengesData={themeChallengesData}
				playerData={this.props.playerData}
				goToChallenge={this.goToChallenge}
				goToNextChallenge={this.goToNextChallenge}
				completeChallenge={this.completeChallenge}
				handleThemeComplete={this.props.handleThemeComplete}
				updatePlayerData={this.props.updatePlayerData}
			/>
		);
	};
}

ChallengeController.propTypes = {
	themeData: PropTypes.object.isRequired,
	themeChallengesData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleThemeComplete: PropTypes.func.isRequired,
	setHint: PropTypes.func.isRequired
};

export default ChallengeController;