/* TEMPLATE
{
	id: '',
	type: 'pairs',
	themeId: null,
	hint: {
		location: '',
		topic: '',
		subtopic: 0,
		popup: '',
		isInfoBoxText: false
	},
	challengeNumber: null,
	question: {
		text: ''
	},
	columnA: [
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
	],
	columnB: [
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
	]
},
*/

const pairsData = [
	{
		id: 'pairs-1',
		type: 'pairs',
		// themeId: 2,
		themeId: 9,
		challengeNumber: 5,
		hint: {
			location: 'butchery',
			topic: 'pig',
			subtopic: null,
			popup: null,
			isInfoBoxText: null,
		},
		question: {
			text: 'Hvilke udskæringer er mest fedtholdige? Klik rundt i grisen og find ud af det.'
		},
		columnA: [
			{id: 'filet-royal', text: 'Filet Royal', image: null, isSelected: false, connectedTo: null, correctConnectionId: 1},
			{id: 'mignon', text: 'Mignon', image: null, isSelected: false, connectedTo: null, correctConnectionId: 2},
			{id: 'skank', text: 'Skanken', image: null, isSelected: false, connectedTo: null, correctConnectionId: 3},
		],
		columnB: [
			{id: 1, text: 'Det mest magre stykke på grisen', image: null, isSelected: false},
			{id: 2, text: 'Magert stykke kød', image: null, isSelected: false},
			{id: 3, text: 'En meget fedtholdig udskæring', image: null, isSelected: false},
		]
	}
];

export {
	pairsData
};